import BigNumber from "bignumber.js";
import { useBRNCKBPoolContract } from 'clients/contracts/hooks';
import { LiquidityPool } from "types/contracts";

const getStakedTokenPrice  =  async (liquidityPool : LiquidityPool) => {

    // const reserve =  (await sdk.api.abi.call({
    //    block,
    //    target: "0xaa0f41e50dbfd8247fb397b1fffea1fea9f4e6d4",
    //    params: [],
    //    abi: brainiacAbi.getReserves,
    //    chain
    //  })).output;

    //  const totalSupplyLp =  (await sdk.api.abi.call({
    //    block,
    //    target: "0xaa0f41e50dbfd8247fb397b1fffea1fea9f4e6d4",
    //    abi: brainiacAbi.totalSupply,
    //    chain
    //  })).output;

    // const liquidityPool = useBRNCKBPoolContract('0x8a01b508d8bF08eE5583743C9E1C8Ec45C22E303');

    try {
        const reserve : any = await liquidityPool.methods.getReserves().call();
        const totalSupplyLp : any = await liquidityPool.methods.totalSupply().call();

         // x = reserve0 / reserve1 | gives  1 token1 = x token0
         // y = reserve1 / reserve0 | gives  1 token0 = y token1
         const x = new BigNumber(reserve._reserve0).multipliedBy(1e18).div(reserve._reserve1).toNumber();
         const y = new BigNumber(reserve._reserve1).multipliedBy(1e18).div(reserve._reserve0).toNumber();

         // fetching ckb price from coingecko in usd
         const res = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=nervos-network&vs_currencies=usd");

         const data = await res.json()
         const ckbPrice = data['nervos-network'].usd;
        //  console.log(ckbPrice)
        //  console.log(reserve)
        //  console.log(totalSupplyLp)


         // brnPrice =  exchangeRate (y) * ckbPrice
         const brnPrice =  new BigNumber(y).multipliedBy(ckbPrice).div(1e18);

         // total value of brn in pool (usd)
         // valueTotalBRN =  reserve0 * brnPrice
         const valueTotalBRN = new BigNumber(reserve._reserve0).multipliedBy(brnPrice).div(1e18);

         // total value of wckb in pool (usd)
         // valueTotalBRN =  reserve1 * ckbPrice
         const valueTotalCKB = new BigNumber(reserve._reserve1).multipliedBy(ckbPrice).div(1e18);

         // lptokenPrice = [($ value total brn in lp) + ($ value total ckb in lp)]/(total supply of LPtoken)
         const lpTokenPrice = new BigNumber(valueTotalBRN).plus(valueTotalCKB).multipliedBy(1e18).div(totalSupplyLp);

         return [ brnPrice , lpTokenPrice  ];
    }catch(e){
        console.log("ERROR",e)
        return [0,0];
    }
   }

   export default getStakedTokenPrice
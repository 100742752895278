import React from 'react';
import { Container , Box, Typography } from '@mui/material';

export const InfoBox = () => {
  return (
    <Box >
      <Box sx={{ padding : "2.5rem",paddingY : "3rem", textAlign : 'center'}}>
        <Typography variant="h1" component="h1" sx={{color : "white", fontSize : "3rem"}}>
          Early Adopter NFT <br/> Collection
        </Typography>
        <Typography variant="h6" component="h6" sx={{color : "white", fontSize : "1.2rem", maxWidth : "800px",paddingY : "1rem", margin : 'auto'}}>
          Brainiac Finance hosted their first IDO on 10th Sept 2022 with YokaiSwap.<br/>
          All IDO participants are eligible for a free utility NFT as a token gratitude for participating in
          the IDO from the development team behind Brainiac Finance.
        </Typography>
      </Box>
    </Box >
  );
};

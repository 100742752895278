import React from 'react';

import { useTranslation } from 'translation';
import { Modal, IModalProps } from 'components';
import { Box, Stack, Typography } from '@mui/material';
import { PrimaryButton } from 'components';
import { useMintNFT } from 'clients/api';
import type { TransactionReceipt } from 'web3-core';
import { VError } from 'errors';
import BigNumber from 'bignumber.js';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';

export interface MintNFT {
  mintNFT: () => Promise<TransactionReceipt | undefined>;
}
export interface IMintNFTModalProps {
  isOpen: boolean;
  onClose: IModalProps['handleClose'];
  account: string;
  mintsAvailable?: string;
}

export const MintNFTModal: React.FC<IMintNFTModalProps> = ({
  isOpen,
  onClose,
  account,
  mintsAvailable,
}) => {
  const { t } = useTranslation();
  const handleTransactionMutation = useHandleTransactionMutation();

    const { mutateAsync: contractMintNFT, isLoading: isMintNFTLoading } = useMintNFT();
    const mintNFT : MintNFT['mintNFT'] = async () => {
      if (!account) {
        // This error should never happen, since the form inside the UI component
        // is disabled if there's no logged in account
        throw new VError({ type: 'unexpected', code: 'undefinedAccountErrorMessage' });
      }
      return contractMintNFT({
        fromAccountAddress: account
      });
    };

   const handleMint = ()=> {
    console.log("minted")
    return handleTransactionMutation({
      mutate: () => mintNFT(),
      successTransactionModalProps: transactionReceipt => ({
        title: t('mintRepayBai.mintBai.successfulTransactionModal.title'),
        content: t('mintRepayBai.mintBai.successfulTransactionModal.message'),
        amount: {
          valueWei: new BigNumber(0),
          tokenId: 'bai',
        },
        transactionHash: transactionReceipt.transactionHash,
      }),
    });
  };

  return (
    <Modal
      className="brainiac-modal"
      isOpened={isOpen}
      handleClose={onClose}
      noHorizontalPadding={!account}
      title={<h4>Brainiac NFT</h4>}
    >
      <Stack spacing={4}>
        <Stack direction="row" justifyContent="space-between">
          <Typography>Available NFT mints</Typography>
          <Typography>{mintsAvailable}</Typography>
        </Stack>
        <Stack justifyContent="center">
          <PrimaryButton onClick={handleMint} disabled={isMintNFTLoading}>mint</PrimaryButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

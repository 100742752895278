/** @jsxImportSource @emotion/react */
import React from 'react';
import { InfoBox } from './InfoBox';
import { FAQ } from './FAQ';
import { AuthContext } from 'context/AuthContext';
import { NFTMintSection } from './NFTMintSection';
const MarketUi: React.FC = () => {
  const { account } = React.useContext(AuthContext);
  return (
    <div>
      <InfoBox />
      <NFTMintSection />
      <FAQ />
    </div>
  );
};

const NFTMarket: React.FC = () => <MarketUi />;

export default NFTMarket;
